<template>
    <div>
        <div class="row">
            
        </div> 
    </div>    
</template>

<script>
const name = 'Tasks';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    computed: {
        ...mapGetters(["getAllPages"])
    },
    methods: {
        ...mapActions(["makeActiveTab", "addTab"])
    },
    created(){
        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    }
}
</script>